"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header = void 0;
const BaseHeader_1 = require("./BaseHeader");
const Style_1 = require("../style/Style");
class Header extends BaseHeader_1.BaseHeader {
    get StyleClass() {
        return Style_1.Style;
    }
    drawInternal(value, context, style, helper, _grid, { drawCellBase }) {
        const { textAlign, textBaseline, color, font, bgColor, textOverflow } = style;
        if (bgColor) {
            drawCellBase({
                bgColor,
            });
        }
        helper.text(value, context, {
            textAlign,
            textBaseline,
            color,
            font,
            textOverflow,
        });
    }
}
exports.Header = Header;
