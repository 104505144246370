"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePasteRangeBoxValues = void 0;
function parsePasteRangeBoxValues(value) {
    const normalizeValue = value.replace(/\r?\n$/, "");
    const lines = normalizeValue.split(/(?:\r?\n)|[\u2028\u2029]/g);
    const values = lines.map((line) => line.split(/\t/g));
    const colCount = values.reduce((n, cells) => Math.max(n, cells.length), 0);
    return {
        colCount,
        rowCount: values.length,
        getCellValue(offsetCol, offsetRow) {
            var _a;
            return ((_a = values[offsetRow]) === null || _a === void 0 ? void 0 : _a[offsetCol]) || "";
        },
    };
}
exports.parsePasteRangeBoxValues = parsePasteRangeBoxValues;
