"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyDataCache = exports.newEmptyColumnData = exports.newEmptyHeaderData = void 0;
const columns = __importStar(require("../../../columns"));
const headerType = __importStar(require("../../../header/type"));
let seqId = -1;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function newEmptyHeaderData() {
    return {
        id: seqId--,
        define: {},
        headerType: headerType.of(null), // default
    };
}
exports.newEmptyHeaderData = newEmptyHeaderData;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function newEmptyColumnData() {
    return {
        id: seqId--,
        define: {},
        columnType: columns.type.of(null),
        style: null,
    };
}
exports.newEmptyColumnData = newEmptyColumnData;
class EmptyDataCache {
    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.headers = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.columns = [];
    }
    getHeader(col, row) {
        const rows = this.headers[row] || (this.headers[row] = []);
        return rows[col] || (rows[col] = newEmptyHeaderData());
    }
    getBody(col, row) {
        const rows = this.columns[row] || (this.columns[row] = []);
        return rows[col] || (rows[col] = newEmptyColumnData());
    }
}
exports.EmptyDataCache = EmptyDataCache;
