"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultilineTextHeaderStyle = void 0;
const Style_1 = require("./Style");
let defaultStyle;
class MultilineTextHeaderStyle extends Style_1.Style {
    constructor(style = {}) {
        super(style);
        this._lineHeight = style.lineHeight || "1em";
        this._autoWrapText = style.autoWrapText || false;
        this._lineClamp = style.lineClamp;
    }
    static get DEFAULT() {
        return defaultStyle
            ? defaultStyle
            : (defaultStyle = new MultilineTextHeaderStyle());
    }
    clone() {
        return new MultilineTextHeaderStyle(this);
    }
    get lineHeight() {
        return this._lineHeight;
    }
    set lineHeight(lineHeight) {
        this._lineHeight = lineHeight;
        this.doChangeStyle();
    }
    get lineClamp() {
        return this._lineClamp;
    }
    set lineClamp(lineClamp) {
        this._lineClamp = lineClamp;
        this.doChangeStyle();
    }
    get autoWrapText() {
        return this._autoWrapText;
    }
    set autoWrapText(autoWrapText) {
        this._autoWrapText = autoWrapText;
        this.doChangeStyle();
    }
}
exports.MultilineTextHeaderStyle = MultilineTextHeaderStyle;
