"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckHeader = void 0;
const BaseHeader_1 = require("./BaseHeader");
const CheckHeaderStyle_1 = require("../style/CheckHeaderStyle");
const symbolManager_1 = require("../../internal/symbolManager");
const utils_1 = require("../../internal/utils");
const CHECK_HEADER_STATE_ID = (0, symbolManager_1.getCheckHeaderStateId)();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getState(grid) {
    let state = grid[CHECK_HEADER_STATE_ID];
    if (!state) {
        state = { elapsed: {}, block: {} };
        utils_1.obj.setReadonly(grid, CHECK_HEADER_STATE_ID, state);
    }
    return state;
}
class CheckHeader extends BaseHeader_1.BaseHeader {
    get StyleClass() {
        return CheckHeaderStyle_1.CheckHeaderStyle;
    }
    clone() {
        return new CheckHeader(this);
    }
    drawInternal(value, context, style, helper, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    grid, { drawCellBase }) {
        const { textAlign, textBaseline, borderColor, checkBgColor, uncheckBgColor, bgColor, color, font, textOverflow, } = style;
        if (bgColor) {
            drawCellBase({
                bgColor,
            });
        }
        const { col, row } = context;
        const range = grid.getCellRange(col, row);
        const cellKey = `${range.start.col}:${range.start.row}`;
        const { elapsed: { [cellKey]: elapsed }, } = getState(grid);
        const checked = grid.getHeaderValue(range.start.col, range.start.row);
        const opt = {
            textAlign,
            textBaseline,
            borderColor,
            checkBgColor,
            uncheckBgColor,
        };
        if (elapsed != null) {
            opt.animElapsedTime = elapsed;
        }
        const inlineCheck = helper.buildCheckBoxInline(!!checked, context, opt);
        helper.text([inlineCheck, value], context, {
            textAlign,
            textBaseline,
            color,
            font,
            textOverflow,
        });
    }
}
exports.CheckHeader = CheckHeader;
