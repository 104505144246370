"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Theme = void 0;
const utils_1 = require("../internal/utils");
const symbolManager_1 = require("../internal/symbolManager");
//private symbol
const _ = (0, symbolManager_1.get)();
function getProp(obj, superObj, names, defNames) {
    return ((0, utils_1.getChainSafe)(obj, ...names) ||
        (0, utils_1.getChainSafe)(superObj, ...names) ||
        (defNames && (0, utils_1.getChainSafe)(obj, ...defNames)) ||
        (defNames && (0, utils_1.getChainSafe)(superObj, ...defNames)));
}
class Theme {
    constructor(obj, superTheme) {
        this._checkbox = null;
        this._radioButton = null;
        this._button = null;
        this._header = null;
        this._messages = null;
        this[_] = {
            obj,
            superTheme: superTheme,
        };
    }
    get font() {
        const { obj, superTheme } = this[_];
        return getProp(obj, superTheme, ["font"]);
    }
    get underlayBackgroundColor() {
        const { obj, superTheme } = this[_];
        return getProp(obj, superTheme, ["underlayBackgroundColor"]);
    }
    // color
    get color() {
        const { obj, superTheme } = this[_];
        return getProp(obj, superTheme, ["color"]);
    }
    get frozenRowsColor() {
        const { obj, superTheme } = this[_];
        return getProp(obj, superTheme, ["frozenRowsColor"], ["color"]);
    }
    // background
    get defaultBgColor() {
        const { obj, superTheme } = this[_];
        return getProp(obj, superTheme, ["defaultBgColor"]);
    }
    get frozenRowsBgColor() {
        const { obj, superTheme } = this[_];
        return getProp(obj, superTheme, ["frozenRowsBgColor"], ["defaultBgColor"]);
    }
    get selectionBgColor() {
        const { obj, superTheme } = this[_];
        return getProp(obj, superTheme, ["selectionBgColor"], ["defaultBgColor"]);
    }
    get highlightBgColor() {
        if (this.hasProperty(["highlightBgColor"])) {
            const { obj, superTheme } = this[_];
            return getProp(obj, superTheme, ["highlightBgColor"]);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (args) => {
            const color = args.row < args.grid.frozenRowCount
                ? this.frozenRowsBgColor
                : this.defaultBgColor;
            if (typeof color === "function") {
                return color(args);
            }
            return color;
        };
    }
    // border
    get borderColor() {
        const { obj, superTheme } = this[_];
        return getProp(obj, superTheme, ["borderColor"]);
    }
    get frozenRowsBorderColor() {
        const { obj, superTheme } = this[_];
        return getProp(obj, superTheme, ["frozenRowsBorderColor"], ["borderColor"]);
    }
    get highlightBorderColor() {
        const { obj, superTheme } = this[_];
        return getProp(obj, superTheme, ["highlightBorderColor"], ["borderColor"]);
    }
    get checkbox() {
        const { obj, superTheme } = this[_];
        return (this._checkbox ||
            (this._checkbox = {
                get uncheckBgColor() {
                    return getProp(obj, superTheme, ["checkbox", "uncheckBgColor"], ["defaultBgColor"]);
                },
                get checkBgColor() {
                    return getProp(obj, superTheme, ["checkbox", "checkBgColor"], ["borderColor"]);
                },
                get borderColor() {
                    return getProp(obj, superTheme, ["checkbox", "borderColor"], ["borderColor"]);
                },
            }));
    }
    get radioButton() {
        const { obj, superTheme } = this[_];
        return (this._radioButton ||
            (this._radioButton = {
                get checkColor() {
                    return getProp(obj, superTheme, ["radioButton", "checkColor"], ["color"]);
                },
                get uncheckBorderColor() {
                    return getProp(obj, superTheme, ["radioButton", "uncheckBorderColor"], ["borderColor"]);
                },
                get checkBorderColor() {
                    return getProp(obj, superTheme, ["radioButton", "checkBorderColor"], ["borderColor"]);
                },
                get uncheckBgColor() {
                    return getProp(obj, superTheme, ["radioButton", "uncheckBgColor"], ["defaultBgColor"]);
                },
                get checkBgColor() {
                    return getProp(obj, superTheme, ["radioButton", "checkBgColor"], ["defaultBgColor"]);
                },
            }));
    }
    get button() {
        const { obj, superTheme } = this[_];
        return (this._button ||
            (this._button = {
                get color() {
                    return getProp(obj, superTheme, ["button", "color"], ["color"]);
                },
                get bgColor() {
                    return getProp(obj, superTheme, ["button", "bgColor"], ["defaultBgColor"]);
                },
            }));
    }
    get header() {
        const { obj, superTheme } = this[_];
        return (this._header ||
            (this._header = {
                get sortArrowColor() {
                    return getProp(obj, superTheme, ["header", "sortArrowColor"], ["color"]);
                },
            }));
    }
    get messages() {
        const { obj, superTheme } = this[_];
        return (this._messages ||
            (this._messages = {
                get infoBgColor() {
                    return getProp(obj, superTheme, ["messages", "infoBgColor"]);
                },
                get errorBgColor() {
                    return getProp(obj, superTheme, ["messages", "errorBgColor"]);
                },
                get warnBgColor() {
                    return getProp(obj, superTheme, ["messages", "warnBgColor"]);
                },
            }));
    }
    hasProperty(names) {
        const { obj, superTheme } = this[_];
        return hasThemeProperty(obj, names) || hasThemeProperty(superTheme, names);
    }
    extends(obj) {
        return new Theme(obj, this);
    }
}
exports.Theme = Theme;
function hasThemeProperty(obj, names) {
    if (obj instanceof Theme) {
        return obj.hasProperty(names);
    }
    else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let o = obj;
        if (!o) {
            return false;
        }
        for (let index = 0; index < names.length; index++) {
            const name = names[index];
            o = o[name];
            if (!o) {
                return false;
            }
        }
        return !!o;
    }
}
