"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadIcons = void 0;
const icons = __importStar(require("../../internal/icons"));
const utils_1 = require("../../internal/utils");
function loadIcons(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
icon, context, helper, callback) {
    let argIcon = undefined;
    if (icon) {
        if ((0, utils_1.isPromise)(icon)) {
            icon.then((i) => {
                loadIcons(i, context.toCurrentContext(), helper, callback);
            });
        }
        else {
            const iconList = icons.toNormarizeArray(icon);
            iconList.forEach((i) => {
                if (i.font && i.content) {
                    helper.testFontLoad(i.font, i.content, context);
                }
            });
            argIcon = iconList;
        }
    }
    callback(argIcon, context);
}
exports.loadIcons = loadIcons;
