"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortHeaderStyle = void 0;
const Style_1 = require("./Style");
let defaultStyle;
class SortHeaderStyle extends Style_1.Style {
    constructor(style = {}) {
        super(style);
        this._sortArrowColor = style.sortArrowColor;
    }
    static get DEFAULT() {
        return defaultStyle ? defaultStyle : (defaultStyle = new SortHeaderStyle());
    }
    get sortArrowColor() {
        return this._sortArrowColor;
    }
    set sortArrowColor(sortArrowColor) {
        this._sortArrowColor = sortArrowColor;
        this.doChangeStyle();
    }
    clone() {
        return new SortHeaderStyle(this);
    }
}
exports.SortHeaderStyle = SortHeaderStyle;
