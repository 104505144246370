"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortHeader = void 0;
const BaseHeader_1 = require("./BaseHeader");
const SortHeaderStyle_1 = require("../style/SortHeaderStyle");
const utils_1 = require("../../internal/utils");
const canvases_1 = require("../../internal/canvases");
class SortHeader extends BaseHeader_1.BaseHeader {
    get StyleClass() {
        return SortHeaderStyle_1.SortHeaderStyle;
    }
    drawInternal(value, context, style, helper, grid, { drawCellBase }) {
        const { textAlign, textBaseline = "middle", color, bgColor, font, textOverflow, sortArrowColor, } = style;
        if (bgColor) {
            drawCellBase({
                bgColor,
            });
        }
        const state = grid.sortState;
        let order = undefined;
        const { col, row } = context;
        const range = grid.getCellRange(col, row);
        if ((0, utils_1.cellInRange)(range, state.col, state.row)) {
            ({ order } = state);
        }
        const ctx = context.getContext();
        const arrowSize = (0, canvases_1.getFontSize)(ctx, font).width * 1.2;
        helper.text(value, context, {
            textAlign,
            textBaseline,
            color,
            font,
            textOverflow,
            icons: [
                {
                    name: order != null
                        ? order === "asc"
                            ? "arrow_downward"
                            : "arrow_upward"
                        : undefined,
                    width: arrowSize,
                    color: helper.getColor(sortArrowColor || helper.theme.header.sortArrowColor, col, row, ctx) || "rgba(0, 0, 0, 0.38)",
                },
            ],
        });
    }
}
exports.SortHeader = SortHeader;
