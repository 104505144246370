"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultilineTextHeader = void 0;
const BaseHeader_1 = require("./BaseHeader");
const MultilineTextHeaderStyle_1 = require("../style/MultilineTextHeaderStyle");
class MultilineTextHeader extends BaseHeader_1.BaseHeader {
    get StyleClass() {
        return MultilineTextHeaderStyle_1.MultilineTextHeaderStyle;
    }
    clone() {
        return new MultilineTextHeader(this);
    }
    drawInternal(value, context, style, helper, _grid, { drawCellBase }) {
        const { textAlign, textBaseline, color, font, bgColor, lineHeight, autoWrapText, lineClamp, textOverflow, } = style;
        if (bgColor) {
            drawCellBase({
                bgColor,
            });
        }
        const multilines = value
            .replace(/\r?\n/g, "\n")
            .replace(/\r/g, "\n")
            .split("\n");
        helper.testFontLoad(font, value, context);
        helper.multilineText(multilines, context, {
            textAlign,
            textBaseline,
            color,
            font,
            lineHeight,
            autoWrapText,
            lineClamp,
            textOverflow,
        });
    }
}
exports.MultilineTextHeader = MultilineTextHeader;
